import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import {generarTema} from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import ClienteProvider from './componentes/context/ClienteContext';
import Contenedor from './componentes/layout/Contenedor'
import Login from './componentes/auth/Login'
import { ToastContainer } from 'react-toastify';
//import Chat from './componentes/ChatWhats'
import ChatArchivo from './componentes/ChatWhats/ChatArch';
import ChatLista from './componentes/ChatLista';
import ChatListaContacto from './componentes/ChatListaContacto';
import ChatListaRV from './componentes/ChatListaRV'
import ChatListaRL from './componentes/ChatListaRL';
//import ListaDifusion from './componentes/ListaDifusion/ListaDifusion';
import DifusionIndex from './componentes/ListaDifusion/DifusionIndex';
import 'react-toastify/dist/ReactToastify.css';
import CloneChat from './componentes/CloneWhats/ChatIndex'
import MensajeMasiva from './componentes/MensajeMasiva';
function App() {
  const [colorTema,setColorTema] = React.useState(false);
  React.useEffect(()=>{
    const auxColor= localStorage.getItem('ColorTheme');
    if ( auxColor===undefined || auxColor===null) 
    {
      localStorage.setItem('ColorTheme',colorTema);
      console.log("sin el tema");
    }
    else
    {  console.log("cargando el tema");
      //console.log(JSON.parse(auxColor));
      setColorTema(JSON.parse(auxColor))    
    }
  },[])

  return (
  <React.Fragment >
    <AuthProvider>
      <GeneralesProvider>
        <ClienteProvider>
        <Router>
          <ThemeProvider theme={generarTema(colorTema)}>
            <Contenedor colorTema={colorTema} setColorTema={setColorTema}>              
              <Switch>
                <Route exact path="/" component={Login}  />          
                {/* <Route exact path="/chat" component={Chat}  />    */}
                <Route exact path="/chat" component={ChatArchivo}  />   
                <Route exact path="/chat-difusion-general" component={ChatLista}  />   
                <Route exact path="/chat-difusion-admcorcholata" component={ChatListaRV}  />  
                <Route exact path="/chat-difusion-corcholata" component={ChatListaRL}  />   
                <Route exact path="/chat-difusion-estructura" component={ChatListaContacto}  />   
                <Route exact path="/catalogo-lista-difusion" component={DifusionIndex}  />
                <Route exact path="/difusion-alcance" component={MensajeMasiva}  />
                <Route exact path="/chat-difusion" component={CloneChat}  />
                <Route path="*" component={  Login } />
              </Switch>              
            </Contenedor>
          </ThemeProvider>
          <ToastContainer autoClose={5000} closeOnClick/>
        </Router>
        </ClienteProvider>
      </GeneralesProvider>
    </AuthProvider>
  </React.Fragment>
  );
}

export default App;