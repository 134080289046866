import { useEffect, useState, useRef } from "react";
import { Avatar, IconButton, TextField, Box,
  Card, Typography, Grid, CardActionArea, MenuItem,
  MenuList, Popper, Fade, Paper, ClickAwayListener, Button,
  CardContent, } from "@material-ui/core";
import {
  AccessTime, AttachFile, InsertDriveFile,
  //MoreVert,Check,SearchOutlined,
  Headset, VideoLibrary, PhotoLibrary,
  Gif, PictureAsPdf, Close, GetApp, } from "@material-ui/icons";
//import MicNoneOutlinedIcon from "@material-ui/icons/MicNoneOutlined";
//import EmojiEmotionsOutlinedIcon from "@material-ui/icons/EmojiEmotionsOutlined";
import { Redirect, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SendIcon from "@material-ui/icons/Send";
import Picker from "emoji-picker-react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ErrorGeneral,
  llamaApiCargaToken,
  llamadaApiCarga,
  apiExtCargaToken,
} from "../funciones/ClienteAxios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import qs from "qs";
import axios from "axios";
import moment from "moment";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import DialogImage from "../generales/DialogImage";

const ENDPOINT = "https://socket-corcholata.daduva.mx:5000";
//const ENDPOINT = "https://137.184.44.9:5000";
const useStyles = makeStyles((theme) => ({
  chat: {
    flex: "0.65",
    display: "flex",
    flexDirection: "column",
    height: "90vh",
  },
  chatHeader: {
    paddingLeft: "1rem",
    paddingTop: ".2rem",
    paddingBottom: ".2rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
  },
  chatHeaderInfo: {
    flex: "1",
    paddingLeft: "20px",
    "& h3": {
      marginBottom: "3px",
      fontWeight: "500",
    },
    "& span": {
      color: "gray",
    },
  },
  chatHeaderRight: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "100px",
  },
  chatBody: {
    flex: "1",
    backgroundColor: "lightgray",
    padding: "30px",
    overflowY: "auto",
  },
  chatMessage: {
    position: "relative",
    fontSize: "1em",
    padding: "10px",
    backgroundColor: "#ffffff",
    color: "black",
    borderRadius: "10px",
    width: "fit-content",
    marginBottom: "20px",
  },
  chatName: {
    position: "absolute",
    fontWeight: "800",
    top: "0px",
    color: "gray",
    fontSize: "xx-small",
  },
  chatTimestamp: {
    fontSize: "xx-small",
    color: "rgba(0, 0, 0, 35)",
    marginTop: "14px",
    marginLeft: "5px",
    float: "right",
    cursor: "default",
  },
  chatReceiver: {
    marginLeft: "auto",
    backgroundColor: "#dcf8c6",
  },
  chatFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "62px",
    borderTop: "1px solid lightgray",
  },
  input: {
    flex: 1,
    borderRadius: "30px",
    border: "none",
    padding: "10px",
  },
}));

let arrFormato = [
  {
    tipo: "MEDIA-IMG",
    sizeMax: 16,
    formato: "jpg, jpeg, gif, png, svg, webp, bmp",
  },
  { tipo: "MEDIA-DOC", sizeMax: 100, formato: "zip, pdf" },
  { tipo: "MEDIA-VID", sizeMax: 16, formato: "mp4, 3gp, mov" },
  { tipo: "MEDIA-AUD", sizeMax: 16, formato: "mp3, aac, ogg" },
];

const Chat = ({ setShowChat, showSidebar, setShowSidebar, numChat }) => {
  const classes = useStyles();
  let history = useHistory();
  const socket = io(ENDPOINT, { transports: ["websocket"] });
  const source = axios.CancelToken.source();
  let UsuId = localStorage.getItem("UsuId");
  let Dispvo = localStorage.getItem("Dispvo");
  let auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
  let auxAccept1 =
    "application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/svg,image/webp,image/bmp,";
  let auxAccept2 =
    auxAccept1 +
    "video/mp4,video/3gp,video/mov,audio/mp3,audio/aac,audio/ogg,.zip";
  const [inputMensaje, setInputMensaje] = useState("");
  const [roomId, setRoomId] = useState(0);
  const [roomName, setRoomName] = useState("");
  const [messages, setMessages] = useState([]);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [espera, setEspera] = useState(false);
  const [espera2, setEspera2] = useState(false);
  const [file, setFile] = useState();
  const [url, setURL] = useState([]);
  const [tipoMedia, setTipoMedia] = useState("");
  const [nombreArchivo, setNombreArchivo] = useState("");
  const [listTel, setListTel] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const anchorRef = useRef(null);
  useEffect(() => {
    if (numChat.length !== 0) {
      const llamaApi = () => {
        let data = qs.stringify({
          Usuario: UsuId,
          Contacto: `+${numChat.telefono}`,
          Instancia: 1,
          idDispositivo: auxDispV,
        });
        let url = "msg-wa-chat-historial";
        function respuesta(auxiliar) {
          if (auxiliar[0].Id) {
            //console.log(auxiliar);
            setMessages(auxiliar);
          }
        }
        llamaApiCargaToken( data, url, respuesta, ErrorGeneral, setEspera, history, source);
      };
      llamaApi();
      let arrNom = { name: numChat.original.name };
      const llenaInfoContacto = () => {
        let listCel = [],
          auxtel = numChat.telefono;
        let cad = `+52 1 ${auxtel.substring(3, 5)} ${auxtel.substring(
          5,
          9
        )} ${auxtel.substring(9, 13)}`;
        listCel.push({ cel: cad });
        let data = qs.stringify({
          Contactos: JSON.stringify(listCel),
          idDispositivo: auxDispV,
        });
        let url = "corcholata-cel-info";
        function respuesta(auxiliar) {
          if (auxiliar[0].orden) {
            let arrMsg = [];
            arrMsg = {
              ...arrNom,
              cel: cad,
              nameOriginal: auxiliar[0].nombre,
              infoEstruc: `${auxiliar[0].seccNom} ${auxiliar[0].munNom}`,
              original: auxiliar[0],
            };
            console.log(arrMsg);
            //setInfoContact(auxiliar)
            setRoomName(arrMsg);
          }
        }
        apiExtCargaToken(
          data,
          url,
          respuesta,
          ErrorGeneral,
          setEspera,
          history,
          source.token
        );
      };
      setRoomName(arrNom);
      llenaInfoContacto();
    }

    setRoomId(numChat);
    return () => {
      source.cancel();
    };
  }, [numChat]);

  useEffect(() => {
    //para recibir la emision
    socket.on("WAInstanceMsgSend", (data) => {
      console.log(`Estoy conectado ${data}`);
    });

    socket.on("WAInstanceMsgReceived", (data) => {
      let dataJS = JSON.parse(data);
      console.log("probando");
      if (dataJS.From.substring(0, 13) === numChat.telefono||dataJS.To.substring(0, 13) === numChat.telefono) {
        let auxCopia = messages;
        console.log(dataJS);
        // console.log(`WAInstanceMsgReceived => ${dataJS}`);
        let auxNvoMsg = {
          fecha: dataJS.fecha,
          timestamp: dataJS.Time,
          body: dataJS.Body,
          author: "",
          fromMe: dataJS.From.substring(0, 13) === numChat.telefono ?false:"true",
          type: dataJS.Type,
          quotedMsg:dataJS.Media,
        };
        auxCopia.push(auxNvoMsg);
        setEspera2(false);
        setRoomName(dataJS.Name);
        setTimeout(() => {
          console.log(auxCopia);
          setEspera2(true);
          setMessages(auxCopia);
        }, 500);
      }
    });

    return () => socket.disconnect();
  });
  const handleClickOpenImage = (event) => {
    setOpenImage(true);
    setImageURL(event.target.src);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const llamaApi = (auxMensaje) => {
    setInputMensaje("");
    let data = qs.stringify({
      Usuario: UsuId,
      Origen: process.env.REACT_APP_TEL_ORI,
      Contacto: `+${numChat.telefono}`,
      Mensaje: auxMensaje,
      Difusion:0,
      idDispositivo: auxDispV,
    });
    let url = "msg-send_1_1";
    function respuestaDuplicado(auxiliar) {
      if (auxiliar) {
        let auxCopia = messages;
        let auxNvoMsg = {
          fecha: auxiliar[0].Mensaje[0].FEnvio,
          body: auxMensaje,
          author: "",
          fromMe: "true",
          type: "chat",
        };
        auxCopia.push(auxNvoMsg);
        setMessages(auxCopia);
        setInputMensaje("");
      } else {
        //let mensaje = "Nombre ya registrado, ingrese un Nombre diferente.";
        let mensaje =
          "Ocurrio un error favor de esperar un poco y reintentar de nuevo"; //"Nombre ya registrado, ingrese un Nombre diferente.";
        toast.warning(mensaje, {
          pauseOnFocusLoss: false,
          toastId: `nvo-perfil${6}`,
        });
        //mandaAlerta(mensaje, "warning");
      }
    }
    llamadaApiCarga(
      data,
      url,
      respuestaDuplicado,
      ErrorGeneral,
      setEspera,
      history
    );
  };
  const onChangeArchivo = (event, valido) => {
    setURL([]);
    setFile();
    setTipoMedia("");
    setNombreArchivo("");
    let auxSize = 0;
    let auxTipMedia = "";
    const regex =
      /([a-zA-Z0-9\s_\.\-\(\):])+(.zip|.pdf|.jpg|.jpeg|.gif|.png|.svg|.webp|.bmp|.mp4|.3gp|.mov|.mp3|.aac|.ogg)$/;
    //let regex2 = new RegExp("[$%@#&+(){}/:;!?,<>= ]", "g");
    if (event.target.files[0]) {
      let arch = event.target.files[0];
      let bandera = false;
      arrFormato.forEach((elemento) => {
        let auxpunto = arch.name.lastIndexOf(".");
        let tipo = arch.name.substring(auxpunto + 1, arch.name.length);
        if (!bandera && elemento.formato.search(tipo) !== -1) {
          bandera = true;
          if (valido === 1) {
            auxTipMedia = "MEDIA-DOC";
            auxSize = 100;
          } else {
            auxTipMedia = elemento.tipo;
            auxSize = elemento.sizeMax;
          }
        }
      });
      if (
        arch.name.toLowerCase().match(regex) &&
        arch.size < auxSize * 1024 * 1024
      ) {
        let nom = "";
        if (arch.name.length >= 23) {
          nom =
            arch.name.substring(0, 22) +
            "..." +
            arch.name.substring(arch.name.lastIndexOf("."), arch.name.length);
        } else {
          nom = arch.name;
        }
        let auxCode = uuidv4();
        let cadFormato = arch.name.slice(
          arch.name.lastIndexOf("."),
          arch.name.length
        );
        let auxNombre = auxCode + cadFormato;
        let myNewFile = new File([arch], auxNombre, { type: arch.type });
        setNombreArchivo({ corto: nom, completo: arch.name });
        toast.info(`Archivo seleccionado ${arch.name}`, {
          pauseOnFocusLoss: false,
          theme: "colored",
          toastId: `arc-sel${0}`,
        });
        setFile(myNewFile);
        console.log(myNewFile);
        setURL(URL.createObjectURL(arch));
        setTipoMedia(auxTipMedia);
      } else {
        let mensaje = `Formato de archivo incorrecto en ${arch.name}.`;
        if (arch && auxSize > 0 && arch.size >= auxSize * 1024 * 1024) {
          mensaje = "El tamaño del archivo debe ser menor a " + auxSize + "mb.";
        }
        toast.warning(mensaje, {
          pauseOnFocusLoss: false,
          theme: "colored",
          toastId: `arch-incorrecto-sel${0}`,
        });
        setFile();
        setURL([]);
      }
    } else {
      toast.info("Seleccione archivo", {
        pauseOnFocusLoss: false,
        theme: "colored",
        toastId: `no-sel${0}`,
      });
    }
  };
  const handleImage = (event) => {
    //console.log("entrando imangen");
    onChangeArchivo(event, 0);
  };
  const handleAudio = (event) => {
    onChangeArchivo(event, 0);
  };
  const handleVideo = (event) => {
    onChangeArchivo(event, 0);
  };
  const handleArchivo = (event) => {
    onChangeArchivo(event, 1);
  };

  const opcionMenu = (llave, nomId, titulo, Icono) => {
    return (
      <MenuItem key={llave} style={{ alignItems: "center" }}>
        <label htmlFor={nomId}>
          <Box display={"flex"} alignItems="center">
            {Icono}
            <Box display={"flex"} ml={1} style={{ textAlign: "center" }}>
              <Typography variant="inherit">{titulo}</Typography>
            </Box>
          </Box>
        </label>
      </MenuItem>
    );
  };
  const metodoArchivo = () => {
    return (
      <Grid item>
        {!file ? (
          <>
            <input
              accept={
                "image/jpg, image/jpeg, image/gif, image/png, image/svg, image/webp, image/bmp"
              }
              className={classes.input}
              id="tipo-imagen1"
              type="file"
              hidden={true}
              onChange={handleImage}
            />
            <input
              accept={auxAccept2}
              className={classes.input}
              id="tipo-archivo1"
              type="file"
              hidden={true}
              onChange={handleArchivo}
            />
            <input
              accept={".mp4, .3gp, .mov"}
              className={classes.input}
              id="tipo-video1"
              type="file"
              hidden={true}
              onChange={handleVideo}
            />
            <input
              accept={".mp3, .aac, .ogg"}
              className={classes.input}
              id="tipo-audio1"
              type="file"
              hidden={true}
              onChange={handleAudio}
            />
          </>
        ) : null}
        {!file ? (
          <span>
            <IconButton
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="primary"
            >
              <AttachFile size="small" />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 2 }}
            >
              {({ TransitionProps, placement }) => (
                <Fade
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        style={{ padding: 0 }}
                      >
                        {opcionMenu(1, "tipo-archivo1", "Archivo",
                          <InsertDriveFile color="primary" size="small" />
                        )}
                        {opcionMenu( 3, "tipo-audio1", "Audio",
                          <Headset color="primary" size="small" />
                        )}
                        {opcionMenu( 0, "tipo-imagen1", "Imagen",
                          <PhotoLibrary color="primary" size="small" />
                        )}
                        {opcionMenu( 2, "tipo-video1", "Video",
                          <VideoLibrary color="primary" size="small" />
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </span>
        ) : (
          <Box>
            <AttachFile color="disabled" />
          </Box>
        )}
      </Grid>
    );
  };

  const envioSocket = () => {
    console.log("antes de emit");
    socket.emit(
      "WAMsgSend",
      JSON.stringify({
        instancia: "ssdddd",
        id: "23121",
        from: "56231223",
        to: "sscsdff",
        time: "sdfsfsdf",
        body: "prueba de mensaje",
        type: "afsgsfgg",
        media: "assaasdsad",
      })
    );
  };

  const sendMessage = (event) => {
    event.preventDefault();
    if (inputMensaje.trim() !== "" && inputMensaje.length >= 1) {
      console.log(inputMensaje);
      llamaApi(inputMensaje.trim());
      //envioSocket()
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };

  const handleEmojis = () => {
    return (
      <div>
        {chosenEmoji ? (
          <span>Tu elegiste: {chosenEmoji.emoji}</span>
        ) : (
          <span>Sin elegir</span>
        )}
        <Picker onEmojiClick={onEmojiClick} />
      </div>
    );
  };

  const handleBack = () => {
    setShowSidebar(true);
    setShowChat(false);
    return <Redirect to="/clone-whats" />;
  };

  const iconosMensaje = () => {
    return (
      <span
        style={{
          marginTop: "6px",
          marginLeft: "15px",
          float: "right",
          cursor: "default",
        }}
      >
        <AccessTime size="small" />
      </span>
    );
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <div className={classes.chat}>
      <DialogImage openImage={openImage} setOpenImage={setOpenImage}>
        <IconButton onClick={() => setOpenImage(false)}
          style={{ position: "absolute", top: "0", right: "0", color: "white", }}
        >
          <Close />
        </IconButton>
        <img
          style={{ height: "85vh", width: "100%" }}
          title={imageURL} src={imageURL} 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/IMAGEN-NO-DISPONIBLE.png";
          }}
        />
      </DialogImage>
      <div className={classes.chatHeader}>
        {showSidebar === false ? (
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <Avatar />
        )}
        <h5 style={{ marginLeft: 0.5 + "rem" }}>{roomName?.cel}</h5>
        <div className={classes.chatHeaderInfo}>
          <h4
            style={{ fontSize: 10 + "px", color: "#af272f", fontWeight: 700 }}
          >
            {roomName?.infoEstruc}
          </h4>
          <h4>
            {roomName?.nameOriginal ? roomName.nameOriginal : roomName?.name}
          </h4>
        </div>
        <div className={classes.chatHeaderRight}>
          {/*  <IconButton>
            <SearchOutlined />
          </IconButton> */}
          {/* <IconButton>
            <MoreVert />
          </IconButton> */}
        </div>
      </div>
      <div className={classes.chatBody}>
        {messages.map((message, index) => {
          let auxDate, hora;
          if (message.timestamp) {            
            auxDate = new Date(message.timestamp);
          } else {
            auxDate = new Date(message.fecha);
          }
          hora = moment(auxDate);          
          return (
            <div key={index}>
              {message.type === "chat" || message.body !== "" || message.quotedMsg !== "" ? (
                <p
                  className={`${classes.chatMessage} ${
                    message.fromMe === "true" && classes.chatReceiver
                  }`}
                >
                  {message.type === "image" ? (
                    <div style={{ textAlign: "center" }}>
                      <Button variant="outlined" onClick={handleClickOpenImage}>
                        <img
                          style={{ height: "10rem" }}
                          title={message.quotedMsg}  src={message.quotedMsg} 
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/IMAGEN-NO-DISPONIBLE.png";
                          }}
                        />
                      </Button>
                    </div>
                  ) : null}
                  {message.type === "video" ? (
                    <div style={{ textAlign: "center" }}>  
                
                      <video src={message.quotedMsg} width="320" height="240" controls>
                
                      </video>                      
                    </div>
                  ) : null}
                  {message.type === "docs" ? (
                    <div>
                      <a href={message.quotedMsg} download={message.quotedMsg}>
                        <Card className={classes.root}>
                          <CardActionArea>
                            {/*<div style={{*/}
                            {/*    // backgroundImage: `url(${message.quotedMsg})`,*/}
                            {/*    backgroundImage: `url('/IMAGEN-NO-DISPONIBLE.png')`,*/}
                            {/*    backgroundPosition: 'center top',*/}
                            {/*    height: 75, width: '100%'*/}
                            {/*}}></div>*/}
                            <CardContent
                              style={{
                                backgroundColor: "lightgray",
                                color: "black",
                              }}
                            >
                              <Grid
                                container
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Grid item xs={1}>
                                  {message.quotedMsg.split(".").pop() ===
                                  "pdf" ? (
                                    <PictureAsPdf />
                                  ) : message.quotedMsg.split(".").pop() ===
                                    "gif" ? (
                                    <Gif />
                                  ) : (
                                    <InsertDriveFile />
                                  )}
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography noWrap>
                                    {message.quotedMsg.slice(68)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <GetApp />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </a>
                    </div>
                  ) : null}
                  <span className={classes.chatName}>{message.author}</span>
                  {message.body}
                  <time className={classes.chatTimestamp}>
                    {hora.format("hh:mm a")}
                  </time>
                  {/* iconosMensaje() */}
                </p>
              ) : null}
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <div className={classes.chatFooter}>
        {/*  <IconButton aria-label="enviar" color="primary" onClick={sendAudio}>
          <EmojiEmotionsOutlinedIcon onClick={handleEmojis} />
        </IconButton> */}
        {/* metodoArchivo() */}
        <TextField
          size="small"
          id="message"
          value={inputMensaje}
          name="inputMensaje"
          placeholder="Escribe un mensaje"
          onChange={(e) => setInputMensaje(e.target.value)}
          className={classes.input}
        />

        <IconButton aria-label="enviar" color="primary" onClick={sendMessage}>
          <SendIcon size="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default Chat;
